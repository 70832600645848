.mx-1{
  margin-left: 1rem;
  margin-right: 1rem;
}
.my-1{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-2{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mtop-2{
  margin-top: 2rem;
}
.mtop-6{
  margin-top: 6rem;
}

.p-1{
  padding: 1rem;
}
.p-2{
  padding: 2rem;
}

.py-2{
  padding-top: 2rem;
  padding-bottom: 2rem;
}