.modal__content{
  font-size: 1.6rem;
}

.text-container{
  padding: 1rem;
}

.action-list{
  display: flex;
  flex-direction: column;
  min-height: 46vh;
}

.action-list .button{
  font-size: 1.6rem;
  padding: 1.2rem 0rem;
}

.max-w50{
  max-width: 80rem;
  width: 100%;
}
