.row2x1{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}



.homepage__topic{
  width: 100%;
  max-width: 40rem;
  height: 40rem;
  border-radius: 15px;
  box-shadow: 2px 2px 10px black;
  margin: 2rem;
  position: relative;
  overflow: hidden;
  background: #17569e;
}

.homepage__topic h1{
  text-align: center;
  padding: 1rem;
  color: goldenrod;
}

.figure-image{
  width: 100%;
  width: 40rem;
  object-fit: cover;
  object-position: center;
}

.figure-image img{
  height: 30rem;
  width: 100%;
  object-fit: cover;
  position: absolute;
}

figcaption{
  padding: 1.6rem 2rem;
  min-height: 6rem;
  background-color: white;
  font-size: 1.4rem;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}