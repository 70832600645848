html {
  font-size: 62.5%;
}
  
body {
  font-size: 14px;
  font-size: 1.4rem;
  font-family: 'Catamaran', sans-serif;
}
  
h1 {
  font-size: 24px;
  font-size: 2.4rem;
  text-align: center;
}

@import url('https://fonts.googleapis.com/css?family=Catamaran|Hind|Indie+Flower|Rambla&display=swap');


.t-sm{
  font-size: 1.2rem;
}

.t-md{
  font-size: 1.6rem;
}

.t-lg{
  font-size: 1.8rem;
}

.t-xlg{
  font-size: 2rem;
}

/*
font-family: 'Hind', sans-serif;
font-family: 'Indie Flower', cursive;
font-family: 'Catamaran', sans-serif;
font-family: 'Rambla', sans-serif;
*/