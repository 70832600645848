/* DEFAULT */
*,
:before,
:after {
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
            box-sizing: border-box;
}
*{
  margin: 0;
  padding: 0;
}

li{
  list-style: none;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: #0000;
  -webkit-touch-callout: none;
  display: inline-block;
}

button{
  border: none;
  outline: none;
  cursor: pointer;
}

body{
  background: #f4f4f4;
  position: relative;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}


