.interview__question-group{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  align-items: center;
  justify-items: center;
  border: .5px solid #c9c9c9;
  padding: 1rem 0;
  margin: 1rem 0;
}

.interview__question-group .button{
  max-width: 10rem;
  justify-self: flex-end;
  margin: 0 2rem;
}

.interview__question{
  margin: 0 2rem;
  font-size: 1.8rem;
}

.interview-description{
  font-size: 1.8rem;
  padding-top: 2rem;
}

.interview-subtitle{
  margin: 2rem 0 1rem;
  font-size: 2rem;
}