.homepage__hero{
  background: ;
}

@import url('https://fonts.googleapis.com/css?family=Abel|Fjalla+One|Nunito|Varela+Round|Work+Sans&display=swap');
/* 
font-family: 'Nunito', sans-serif;
font-family: 'Work Sans', sans-serif;
font-family: 'Abel', sans-serif;
font-family: 'Fjalla One', sans-serif;
font-family: 'Varela Round', sans-serif;
*/

body{
  font-family: 'Work Sans', sans-serif;
}

.arrow-down{
  position: absolute;
  bottom: 2rem;
  right: 3rem;
  font-size: 3rem;
  color: white;
  animation: 2s linear infinite alternate arrow-anim;
  transform: scale(1);
}

@keyframes arrow-anim{
  0%{
    transform: scale(1.5);

  }

  50%{
    transform: scale(1.8);
  }

  100%{
    transform: scale(1.5);
  }
}

.homepage__header{

  min-height: 100vh;
  position: relative;
  width: 100%;
  top: -5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background-color: #00000031;
  overflow: hidden;
}
.homepage__hero{
  background-image: url('../../images/bigflag.jpg');
  background-size: cover;
  background-position: center;

  position: absolute;
  top: 0;
  right: 0;


  height: 100%;
  width: 130%;
  z-index: -1;
}

.homepage__header h1{
  padding: 1rem;
  color: #fff;
  font-size: calc(2rem + 2vw);
}

.homepage__content{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}




.homepage__description{
  height: 30rem;
  background: #fff;
  font-size: 2rem;
  padding: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -3rem 0 2rem;
}





/* 

.sub-text{
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 1.8rem;
  color: white;
}

.img-figure{
  position: relative;
  cursor: pointer;
}


section h1{
  padding: 1rem;
  background-color: #f0cf3f;
  color: black;
  font-size: 2.2rem;
}

section img{
  width: 100%;
  height: 100%;
  max-height: 30rem;
}

figure{
  object-fit: cover;
  height: 80rem;
}

figcaption{
  padding: 1.6rem 2rem;
  background-color: white;
  font-size: 1.4rem;
  text-align: center;
} */
