.box-list{
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem;
}

.box-list__item{
  text-align: left;
  margin: .5rem 0;
  list-style: decimal;
}

.box-list__title{
  text-decoration: underline;
}