@import url(https://fonts.googleapis.com/css?family=Catamaran|Hind|Indie+Flower|Rambla&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Fjalla+One|Nunito|Varela+Round|Work+Sans&display=swap);
/* DEFAULT */
*,
:before,
:after {
    box-sizing: border-box;
}
*{
  margin: 0;
  padding: 0;
}

li{
  list-style: none;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: #0000;
  -webkit-touch-callout: none;
  display: inline-block;
}

button{
  border: none;
  outline: none;
  cursor: pointer;
}

body{
  background: #f4f4f4;
  position: relative;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}



html {
  font-size: 62.5%;
}
  
body {
  font-size: 14px;
  font-size: 1.4rem;
  font-family: 'Catamaran', sans-serif;
}
  
h1 {
  font-size: 24px;
  font-size: 2.4rem;
  text-align: center;
}


.t-sm{
  font-size: 1.2rem;
}

.t-md{
  font-size: 1.6rem;
}

.t-lg{
  font-size: 1.8rem;
}

.t-xlg{
  font-size: 2rem;
}

/*
font-family: 'Hind', sans-serif;
font-family: 'Indie Flower', cursive;
font-family: 'Catamaran', sans-serif;
font-family: 'Rambla', sans-serif;
*/
.mx-1{
  margin-left: 1rem;
  margin-right: 1rem;
}
.my-1{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-2{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mtop-2{
  margin-top: 2rem;
}
.mtop-6{
  margin-top: 6rem;
}

.p-1{
  padding: 1rem;
}
.p-2{
  padding: 2rem;
}

.py-2{
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.box-list{
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem;
}

.box-list__item{
  text-align: left;
  margin: .5rem 0;
  list-style: decimal;
}

.box-list__title{
  text-decoration: underline;
}
.modal__content{
  font-size: 1.6rem;
}

.text-container{
  padding: 1rem;
}

.action-list{
  display: flex;
  flex-direction: column;
  min-height: 46vh;
}

.action-list .button{
  font-size: 1.6rem;
  padding: 1.2rem 0rem;
}

.max-w50{
  max-width: 80rem;
  width: 100%;
}

.main-layout__center{
  margin-top: 5rem;
}
.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: white;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  html{
    font-size: 82.5%;
  }

  .main-navigation__header-nav {
    display: block;
  }
}
.main-header {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #2586d4;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
}

/* main { margin-top: 5rem; } */

@media (min-width: 768px) {
  .main-header {
    justify-content: space-between;
  }
}
:root{
  --nav-color: #3cb4fa;
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
}

.nav-links a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0.5rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  background: #3cb4fa;
  background: var(--nav-color);
  border-color: #3cb4fa;
  border-color: var(--nav-color);
  color: #ffffff;
}

.nav-links button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #292929;
  color: white;
}

@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
  }

  .nav-links button {
    border: 1px solid white;
    color: white;
    background: transparent;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    background: #3cb4fa;
    background: var(--nav-color);
    color: #292929;
  }
}

.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 70%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.side-drawer a {
  font-size: 2rem;
  letter-spacing: 1.5px;
}

.slide-in-left-enter {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.slide-in-left-enter-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
  -webkit-transition: all 200ms;
  transition: all 200ms;
}

.slide-in-left-exit {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  opacity: 0;
  -webkit-transition: all 200ms;
  transition: all 200ms;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.homepage__hero{
  background: ;
}
/* 
font-family: 'Nunito', sans-serif;
font-family: 'Work Sans', sans-serif;
font-family: 'Abel', sans-serif;
font-family: 'Fjalla One', sans-serif;
font-family: 'Varela Round', sans-serif;
*/

body{
  font-family: 'Work Sans', sans-serif;
}

.arrow-down{
  position: absolute;
  bottom: 2rem;
  right: 3rem;
  font-size: 3rem;
  color: white;
  -webkit-animation: 2s linear infinite alternate arrow-anim;
          animation: 2s linear infinite alternate arrow-anim;
  -webkit-transform: scale(1);
          transform: scale(1);
}

@-webkit-keyframes arrow-anim{
  0%{
    -webkit-transform: scale(1.5);
            transform: scale(1.5);

  }

  50%{
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
  }

  100%{
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

@keyframes arrow-anim{
  0%{
    -webkit-transform: scale(1.5);
            transform: scale(1.5);

  }

  50%{
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
  }

  100%{
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

.homepage__header{

  min-height: 100vh;
  position: relative;
  width: 100%;
  top: -5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background-color: #00000031;
  overflow: hidden;
}
.homepage__hero{
  background-image: url(/static/media/bigflag.9b9aaf67.jpg);
  background-size: cover;
  background-position: center;

  position: absolute;
  top: 0;
  right: 0;


  height: 100%;
  width: 130%;
  z-index: -1;
}

.homepage__header h1{
  padding: 1rem;
  color: #fff;
  font-size: calc(2rem + 2vw);
}

.homepage__content{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}




.homepage__description{
  height: 30rem;
  background: #fff;
  font-size: 2rem;
  padding: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -3rem 0 2rem;
}





/* 

.sub-text{
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 1.8rem;
  color: white;
}

.img-figure{
  position: relative;
  cursor: pointer;
}


section h1{
  padding: 1rem;
  background-color: #f0cf3f;
  color: black;
  font-size: 2.2rem;
}

section img{
  width: 100%;
  height: 100%;
  max-height: 30rem;
}

figure{
  object-fit: cover;
  height: 80rem;
}

figcaption{
  padding: 1.6rem 2rem;
  background-color: white;
  font-size: 1.4rem;
  text-align: center;
} */

.row2x1{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}



.homepage__topic{
  width: 100%;
  max-width: 40rem;
  height: 40rem;
  border-radius: 15px;
  box-shadow: 2px 2px 10px black;
  margin: 2rem;
  position: relative;
  overflow: hidden;
  background: #17569e;
}

.homepage__topic h1{
  text-align: center;
  padding: 1rem;
  color: goldenrod;
}

.figure-image{
  width: 100%;
  width: 40rem;
  object-fit: cover;
  object-position: center;
}

.figure-image img{
  height: 30rem;
  width: 100%;
  object-fit: cover;
  position: absolute;
}

figcaption{
  padding: 1.6rem 2rem;
  min-height: 6rem;
  background-color: white;
  font-size: 1.4rem;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.topic-item__list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.topic-item {
  margin: 1rem 0;
  max-width: 40rem;
  margin: 2rem;
}

.topic-item__card{
  padding: 0;
}

.topic-item__title{
  padding: 1rem;
  text-align: center;
  width: 100%;
}

.topic-item__list-text{
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 18rem;
}

.topic-item__list-text li{
  margin: .5rem 0;
}



.topic-item__content {
  padding: 0;
}

.topic-item__info {
  padding: 1rem;
  text-align: center;
}

.topic-item__image {
  width: 100%;
  height: 20rem;
  margin-right: 1.5rem;
}

.topic-item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.topic-item__info h2,
.topic-item__info h3,
.topic-item__info p {
  margin: 0 0 0.5rem 0;
}

.topic-item__actions {
  padding: 1rem;
  text-align: center;
  border-top: 1px solid #ccc;
}

.topic-item__modal-content {
  padding: 1rem 2rem;
  font-size: 1.6rem;
}

.topic-item__modal-actions {
  text-align: right;
}

.topic-item__actions button,
.topic-item__actions a {
  margin: 0.5rem;
}


.map-container{
  height: 20rem;
  width: 100%;
}


@media (min-width: 768px) {
  .topic-item__image {
    height: 20rem;
  } 
}
.card {
  position: relative;
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  /* padding: 1rem; */
  overflow: hidden;
}

:root{
  --button-primary: #0ea2dd;
  --button-secondary: #28a1d1;
}

.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #0ea2dd;
  border: 1px solid var(--button-primary);
  border-radius: 4px;
  background: #0ea2dd;
  background: var(--button-primary);
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #28a1d1;
  background: var(--button-secondary);
  border-color: #28a1d1;
  border-color: var(--button-secondary);
}

.button--inverse {
  background: transparent;
  color: #0ea2dd;
  color: var(--button-primary);
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #0ea2dd;
  background: var(--button-primary);
}

.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}
.modal {
  z-index: 100;
  position: fixed;
  top: 22vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  overflow: hidden;
}

.modal__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: #0c4d97;
  color: white;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 1rem 0.5rem;
}

.modal__footer {
  padding: 1rem 0.5rem;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  -webkit-transition: all 200ms;
  transition: all 200ms;
}

.modal-exit {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  opacity: 0;
  -webkit-transition: all 200ms;
  transition: all 200ms;
}

.topic-page__list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.topic-page {
  margin: 1rem 0;
  margin: 2rem;
}

.topic-page__card{
  padding: 0;
}

.topic-page__title{
  padding: 1rem;
  text-align: center;
  width: 100%;
}

.topic-page__subtext{
  text-align: left;
  margin: 2rem 0 1rem;
  font-size: 1.8rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem;
  /* border: 1px solid red; */
}
.topic-page__subtext .text-quote{
  font-style: italic;
  padding: 3rem 2rem;
}
.topic-page__subtext .text-spaced{
  margin: .3rem 0;
}
.italic{
  font-style: italic;
}
.underline{
  text-decoration: underline;
}

.subtext-bullet{
  margin: .6rem 0;
  font-size: 2rem;
  padding: 0 1rem;
}



.topic-page__subtitle{
  padding: 1rem;
  font-size: 1.8rem;
}

.subtext-num-list{
  padding: 0 2rem 0 6rem;
}

.subtext-num-list li{
  list-style: decimal;
  margin: .4rem 0;
}


.topic-page__list-text{
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 22rem;
}

.topic-page__list-text li{
  margin: .5rem 0;
}

.topic-page__text-box{
  padding: 2rem 1rem;
  font-size: 1.6rem;
}

.topic-page__text-section{
  margin-top: 2rem;
}


.topic-page__content {
  padding: 0;
}

.topic-page__info {
  padding: 1rem;
  text-align: center;
}

.topic-page__info section{
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 2rem;
}



.topic-large{
  min-height: 52vh;
}

.topic-page__image {
  width: 100%;
  height: 20rem;
  margin-right: 1.5rem;
}

.topic-image-xl{
  height: 30rem;
}

.topic-page__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.topic-page__info h2,
.topic-page__info h3,
.topic-page__info p {
  margin: 0 0 0.5rem 0;
}

.topic-page__actions {
  padding: 1rem;
  text-align: center;
  border-top: 1px solid #ccc;
}

.topic-page__modal-content {
  padding: 0;
}

.topic-page__modal-actions {
  text-align: right;
}

.topic-page__actions button,
.topic-page__actions a {
  margin: 0.5rem;
  font-size: 2rem;
}


.map-container{
  height: 20rem;
  width: 100%;
}


@media (min-width: 768px) {
  .topic-page__image {
    height: 20rem;
  } 
}
.interview__question-group{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  align-items: center;
  justify-items: center;
  border: .5px solid #c9c9c9;
  padding: 1rem 0;
  margin: 1rem 0;
}

.interview__question-group .button{
  max-width: 10rem;
  justify-self: flex-end;
  margin: 0 2rem;
}

.interview__question{
  margin: 0 2rem;
  font-size: 1.8rem;
}

.interview-description{
  font-size: 1.8rem;
  padding-top: 2rem;
}

.interview-subtitle{
  margin: 2rem 0 1rem;
  font-size: 2rem;
}
