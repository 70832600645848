.topic-page__list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.topic-page {
  margin: 1rem 0;
  margin: 2rem;
}

.topic-page__card{
  padding: 0;
}

.topic-page__title{
  padding: 1rem;
  text-align: center;
  width: 100%;
}

.topic-page__subtext{
  text-align: left;
  margin: 2rem 0 1rem;
  font-size: 1.8rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem;
  /* border: 1px solid red; */
}
.topic-page__subtext .text-quote{
  font-style: italic;
  padding: 3rem 2rem;
}
.topic-page__subtext .text-spaced{
  margin: .3rem 0;
}
.italic{
  font-style: italic;
}
.underline{
  text-decoration: underline;
}

.subtext-bullet{
  margin: .6rem 0;
  font-size: 2rem;
  padding: 0 1rem;
}



.topic-page__subtitle{
  padding: 1rem;
  font-size: 1.8rem;
}

.subtext-num-list{
  padding: 0 2rem 0 6rem;
}

.subtext-num-list li{
  list-style: decimal;
  margin: .4rem 0;
}


.topic-page__list-text{
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 22rem;
}

.topic-page__list-text li{
  margin: .5rem 0;
}

.topic-page__text-box{
  padding: 2rem 1rem;
  font-size: 1.6rem;
}

.topic-page__text-section{
  margin-top: 2rem;
}


.topic-page__content {
  padding: 0;
}

.topic-page__info {
  padding: 1rem;
  text-align: center;
}

.topic-page__info section{
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 2rem;
}



.topic-large{
  min-height: 52vh;
}

.topic-page__image {
  width: 100%;
  height: 20rem;
  margin-right: 1.5rem;
}

.topic-image-xl{
  height: 30rem;
}

.topic-page__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.topic-page__info h2,
.topic-page__info h3,
.topic-page__info p {
  margin: 0 0 0.5rem 0;
}

.topic-page__actions {
  padding: 1rem;
  text-align: center;
  border-top: 1px solid #ccc;
}

.topic-page__modal-content {
  padding: 0;
}

.topic-page__modal-actions {
  text-align: right;
}

.topic-page__actions button,
.topic-page__actions a {
  margin: 0.5rem;
  font-size: 2rem;
}


.map-container{
  height: 20rem;
  width: 100%;
}


@media (min-width: 768px) {
  .topic-page__image {
    height: 20rem;
  } 
}