.topic-item__list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.topic-item {
  margin: 1rem 0;
  max-width: 40rem;
  margin: 2rem;
}

.topic-item__card{
  padding: 0;
}

.topic-item__title{
  padding: 1rem;
  text-align: center;
  width: 100%;
}

.topic-item__list-text{
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 18rem;
}

.topic-item__list-text li{
  margin: .5rem 0;
}



.topic-item__content {
  padding: 0;
}

.topic-item__info {
  padding: 1rem;
  text-align: center;
}

.topic-item__image {
  width: 100%;
  height: 20rem;
  margin-right: 1.5rem;
}

.topic-item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.topic-item__info h2,
.topic-item__info h3,
.topic-item__info p {
  margin: 0 0 0.5rem 0;
}

.topic-item__actions {
  padding: 1rem;
  text-align: center;
  border-top: 1px solid #ccc;
}

.topic-item__modal-content {
  padding: 1rem 2rem;
  font-size: 1.6rem;
}

.topic-item__modal-actions {
  text-align: right;
}

.topic-item__actions button,
.topic-item__actions a {
  margin: 0.5rem;
}


.map-container{
  height: 20rem;
  width: 100%;
}


@media (min-width: 768px) {
  .topic-item__image {
    height: 20rem;
  } 
}